






















































































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import moment from 'moment';
import { dateFormat } from '@/utils/helpers';

@Component({
  name: 'DoubleDatePicker',
  filters: {
    dateFormat,
  },
})
export default class DoubleDatePicker extends Vue {
  @Prop({ default: false }) loading!: boolean;

  startDateState = false;
  endDateState = false;

  startDate: string = moment().subtract(1, 'month').format('YYYY-MM-DD');
  endDate: string = moment().format('YYYY-MM-DD');
  error: string | null = null;

  @Watch('startDate')
  onStartDateChange(date: string): void {
    if (moment(this.endDate).isBefore(date)) {
      this.error = `Invalid date provided`;
    } else this.error = null;
  }

  @Watch('endDate')
  onEndDateChange(date: string): void {
    if (moment(date).isBefore(this.startDate)) {
      this.error = `Invalid date provided`;
    } else this.error = null;
  }

  @Emit('refresh')
  refresh(): string {
    return `${this.startDate},${this.endDate}`;
  }
}
